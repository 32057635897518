<template>
  <div>
    <vue-apex-charts
      v-if="seriesData.length > 0"
      type="radialBar"
      height="220"
      :options="chartOptions"
      :series="seriesData"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'RadialChart',
  components: {
    VueApexCharts,
  },
  props: {
    seriesData: {
      type: Array,
      required: true,
    },
    colorData: {
      required: true,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#fff'],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: '#ebe9f1',
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: '#000',
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  created() {
    this.setColors()
  },
  updated() {
    this.setColors()
  },
  methods: {
    setColors() {
      if (this.colorData) {
        this.chartOptions.fill.gradient = {
          ...this.chartOptions.fill.gradient,
          ...{
            gradientToColors: [this.colorData],
          },
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
