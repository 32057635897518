<template>
  <b-card
    no-body
  >
    <b-card-body
      class="d-block d-flex align-items-center"
    >
      <div class="w-100">
        <b-card-title class="font-small-4 text-center">
          {{ title }}
        </b-card-title>
        <radial-chart
          :series-data="score"
          :color-data="color"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardTitle } from 'bootstrap-vue'
import RadialChart from '@/views/App/Reports/charts/Radial_chart.vue'

export default {
  name: 'BmaCard',
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    RadialChart,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    score: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
